import * as styles from "./Header.module.css";

import { Link } from "gatsby";
import React from "react";

export default function Header() {
	return (
		<header className={styles.header}>
			<h1 className={styles.title}>
				<a href="https://adventurestore.me">{"Adventure Store"}</a>{" "}
				<Link to="/" className={styles.title__subtitle}>
					{"Help Center"}
				</Link>
			</h1>
		</header>
	);
}
