import * as styles from "./Layout.module.css";
import Header from "./Header";

import * as React from "react";
import Helmet from "react-helmet";

export default function Layout({ title, children }) {
	return (
		<main className={styles.layout}>
			<Helmet title={title} defer={false} />
			<Header />
			<div className={styles.contents}>{children}</div>
		</main>
	);
}
